import React, { FunctionComponent, useCallback, useContext, useMemo, useState } from 'react';
import { compact } from 'lodash';
import LoadingRing from 'ui/atoms/loading-ring';
import InvestmentSelectDepot, {
  InvestmentWallet,
} from 'subapps/investment/pages/investment/wizard-steps/wallet/select-wallet/select-depot';
import useInvestmentInvitation from 'subapps/investment/pages/investment/hooks/use-investment-invitation';
import useApiCall from 'hooks/use-api-call';
import CreateDepot from 'subapps/investment/pages/investment/wizard-steps/wallet/select-wallet/create-depot';
import CreateCashlinkWallet from 'subapps/investment/pages/investment/wizard-steps/wallet/select-wallet/create-cashlink-wallet';
import { InvestmentsApi, InvitationsApi, WalletsApi } from 'api/apis';
import useOnlyOnce from 'hooks/use-only-once';
import { NetworkTypeEnum, Wallet } from 'api/models';
import useInvestorMe from 'hooks/use-investor-me';
import CreateWallet from 'subapps/investment/pages/investment/wizard-steps/wallet/select-wallet/create-wallet';
import WizardContext from 'libraries/wizard/wizard-context';
import Translate from 'ui/atoms/translate';
import { convertWalletTypeFromApi, convertWalletTypeToApi } from 'core/api/conversions';
import { WalletType } from 'ui/types/wallet';
import { getWalletAddressForNetwork } from 'helper/network';
import WizardHeader from 'libraries/wizard/wizard-header';
import Header from 'ui/atoms/header';
import { useCurrentUserSelector } from 'core/auth/hooks';

const omitWalletTypesForNetworkTypes: { [key: string]: NetworkTypeEnum[] } = {
  [WalletType.GENERIC]: [NetworkTypeEnum.STELLAR],
  [WalletType.CASHLINK]: [],
  [WalletType.TANGANY]: [],
  [WalletType.MOBILE]: [],
};

const SelectWalletStep: FunctionComponent<{}> = () => {
  const {
    resourceId: invitationId,
    investmentId,
    finalize,
    loading: contextLoading,
    isTransferInvitation,
  } = useContext(WizardContext);

  const { reload, loading: invitationLoading, token } = useInvestmentInvitation(invitationId);

  const { investor, error: investorError } = useInvestorMe();
  const { currentUser } = useCurrentUserSelector();

  const investmentToken = token;

  const [nonExistingWalletTypeSelection, setNonExistingWalletTypeSelection] = useState<WalletType | null>();

  const [wallets, setWallets] = useState<Wallet[] | null>();

  const { error: apiError, loading: apiLoading, makeAuthenticatedApi, withApi } = useApiCall();

  const investmentsApi: InvestmentsApi = useMemo(() => makeAuthenticatedApi(InvestmentsApi), [makeAuthenticatedApi]);
  const invitationsApi: InvitationsApi = useMemo(() => makeAuthenticatedApi(InvitationsApi), [makeAuthenticatedApi]);

  const walletsApi: WalletsApi = useMemo(() => makeAuthenticatedApi(WalletsApi), [makeAuthenticatedApi]);

  useOnlyOnce(() => {
    withApi(async () => {
      setWallets(await walletsApi.walletsList());
    });
  });

  const associateWallet = useCallback(
    async (walletId: string) => {
      await withApi(async () => {
        if (isTransferInvitation) {
          await invitationsApi.invitationsWalletAssociationCreate({
            id: invitationId,
            investmentWalletAssociationRequest: { id: walletId },
          });
        } else {
          await investmentsApi.investmentsWalletAssociationCreate({
            id: investmentId,
            investmentWalletAssociationRequest: { id: walletId },
          });
        }

        reload();
        finalize();
      });
    },
    [withApi, investmentsApi, invitationsApi, invitationId, finalize, reload],
  );

  const createWallet = useCallback(
    async (address: string) => {
      await withApi(async () => {
        if (nonExistingWalletTypeSelection === WalletType.GENERIC) {
          const { id } = await walletsApi.walletsGenericWalletCreate({
            genericWalletCreationRequest: { address },
          });
          if (id) await associateWallet(id);
        }
      });
    },
    [withApi, associateWallet, walletsApi, nonExistingWalletTypeSelection, isTransferInvitation],
  );

  const createWalletSelection = useCallback(
    async (walletType: WalletType.MOBILE, isTransferInvitation) => {
      await withApi(async () => {
        if (isTransferInvitation) {
          await invitationsApi.invitationsWalletSelectionCreate({
            id: invitationId,
            investmentWalletSelectionRequest: {
              walletType: convertWalletTypeToApi(walletType),
            },
          });
        } else {
          try {
            await investmentsApi.investmentsWalletSelectionCreate({
              id: investmentId,
              investmentWalletSelectionRequest: {
                walletType: convertWalletTypeToApi(walletType),
              },
            });
          } catch (e) {
            console.error(e);
          }
        }
        reload();
        finalize();
      });
    },
    [withApi, investmentsApi, invitationsApi, finalize, invitationId, reload],
  );

  const onDepotSelected = useCallback(
    async (selectedWallet: InvestmentWallet) => {
      if (!selectedWallet) return null;
      // existing wallet:
      if (selectedWallet.address) {
        if (selectedWallet.id) await associateWallet(selectedWallet.id);
      }
      // non-existing mobile safekeeping wallet:
      else if (selectedWallet.type === WalletType.MOBILE) {
        await createWalletSelection(selectedWallet.type, isTransferInvitation);
      }
      // other non-existing wallets:
      else {
        setNonExistingWalletTypeSelection(selectedWallet.type);
      }
    },
    [associateWallet, setNonExistingWalletTypeSelection, createWalletSelection],
  );

  const prioritizedAllowedWalletTypes: WalletType[] = compact([
    investmentToken?.canUseCashlinkWallet && WalletType.CASHLINK,
    investmentToken?.canUseTanganyWallet && WalletType.TANGANY,
    investmentToken?.canUseMobileWallet && WalletType.MOBILE,
    WalletType.GENERIC,
  ]).filter((walletType) => {
    // certain wallets are disabled for certain network types, see omitWalletTypesForNetworkTypes
    const omitWalletType = omitWalletTypesForNetworkTypes[walletType];
    return !(token?.tokenizationInfo?.networkType && omitWalletType.includes(token?.tokenizationInfo?.networkType));
  });

  const existingSelectableWallets: InvestmentWallet[] = useMemo(
    () =>
      compact(
        (wallets || []).map((wallet) => {
          const { id, type } = wallet;
          // TODO(mara-cashlink): handle case when investmentToken === undefined !?!
          const address =
            investmentToken &&
            investmentToken?.tokenizationInfo &&
            getWalletAddressForNetwork(investmentToken?.tokenizationInfo.networkType, wallet);
          if (!id || !address || !type) return null;
          if (prioritizedAllowedWalletTypes.includes(convertWalletTypeFromApi(wallet.type))) {
            return { id, address, type: convertWalletTypeFromApi(wallet.type) };
          }
          return null;
        }),
      ),
    [wallets, prioritizedAllowedWalletTypes, investmentToken],
  );

  const title = <Translate name="investmentDepot.title" />;

  // todo(geforcefan): we should think about error handling
  //  for our whole investment process, ux team will think about a solution

  if (investorError) return null;

  if (!invitationId || !wallets || !investor) return <LoadingRing />;

  return (
    <>
      <WizardHeader />
      <Header size="large" spacing="xlarge">
        {title}
      </Header>
      {investmentToken?.canUseCashlinkWallet && nonExistingWalletTypeSelection === WalletType.CASHLINK && (
        <CreateCashlinkWallet
          invitationId={invitationId}
          onWalletCreated={associateWallet}
          onCancel={() => setNonExistingWalletTypeSelection(null)}
          loading={apiLoading}
          error={apiError}
        />
      )}
      {investmentToken?.canUseTanganyWallet && nonExistingWalletTypeSelection === WalletType.TANGANY && (
        <CreateDepot
          invitationId={invitationId}
          onWalletCreated={associateWallet}
          onCancel={() => setNonExistingWalletTypeSelection(null)}
          loading={apiLoading}
          error={apiError}
        />
      )}
      {nonExistingWalletTypeSelection === WalletType.GENERIC && (
        <CreateWallet
          onSubmit={({ address }) => createWallet(address)}
          onCancel={() => setNonExistingWalletTypeSelection(null)}
          type={nonExistingWalletTypeSelection}
          loading={apiLoading}
          error={apiError}
        />
      )}
      {!nonExistingWalletTypeSelection && (
        <InvestmentSelectDepot
          loading={apiLoading || invitationLoading || contextLoading}
          error={apiError}
          existingWallets={existingSelectableWallets}
          onDepotSelected={onDepotSelected}
          prioritizedAllowedWalletTypes={prioritizedAllowedWalletTypes}
          tfaDevice={currentUser?.tfaDevice || undefined}
        />
      )}
    </>
  );
};

export default SelectWalletStep;
