
import React, { FunctionComponent, useCallback, useState } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import { nullMoney, toNumber } from 'ui/helper/money';
import { CurrencyRate, RuleSet, RuleType } from 'ui/types/investment';
import { Modal, makeModalForm, ModalHeader, ModalContent, ModalFooter, ModalFooterButtons } from 'ui/molecules/modal';
import Translate from 'ui/atoms/translate';
import Currency from 'ui/atoms/currency';
import { compact, debounce } from 'lodash';
import NumberComponent from 'ui/atoms/number';
import Button from 'ui/atoms/button';
import Hint from 'ui/atoms/hint';
import { find, get } from 'lodash';
import useTranslate from 'ui/hooks/use-translate';
import Segment from 'ui/atoms/segment';
import useClipboard from 'ui/hooks/use-clipboard';
import ServerError from 'ui/types/server-error';
import { StateValues } from 'react-use-form-state';
import { CountryEnum, DistributionPlatform, Money } from 'api';
import useCountries from 'hooks/use-countries';

export enum CampaignRuleType {
  MinimumTicketSize = 'minimum_ticket_size',
  PublicOffering = 'public_offering',
}

export interface CreateCampaignFields {
  distributionPlatform: string;
  name: string | null;
  allocation: string;
  minNumberOfTokens: string;
  maxNumberOfTokens: string;
  currency: string;
  country: CountryEnum;
  pricePerToken: string;
  ruleType: CampaignRuleType;
  singleRuleType?: boolean;
  legalPersonsAllowed: 'true' | 'false';
  naturalPersonsAllowed: 'true' | 'false';
}

export interface CreateCampaignModalProps {
  /** Additional classes. */
  className?: string;

  /** On submit callback */
  onSubmit?: (values: CreateCampaignFields) => void;

  /** On change callback */
  onChange?: (nextStateValues: CreateCampaignFields) => void;

  onCancelTriggered?: () => void;

  onCloseAfterSuccess?: () => void;

  /** Open? */
  open?: boolean;

  ruleSets?: RuleSet[];

  backToBackMinPrice?: Money;

  investmentTotalMax?: Money;

  investmentTotalMin?: Money;

  distributionPlatforms?: DistributionPlatform[];

  allocationUpperLimit?: number;

  calculationError?: ServerError;

  createCampaignError?: ServerError;

  campaignLink?: string;

  currencies?: CurrencyRate[];

  loading?: boolean;
}

const CreateCampaignForm = makeModalForm<CreateCampaignFields>();

const CreateCampaignModal: FunctionComponent<CreateCampaignModalProps> = (props) => {
  const {
    className,
    open = false,
    onSubmit = () => {},
    onChange = () => {},
    onCancelTriggered = () => {},
    onCloseAfterSuccess = () => {},
    backToBackMinPrice = nullMoney,
    investmentTotalMax,
    investmentTotalMin,
    distributionPlatforms = [],
    allocationUpperLimit,
    calculationError,
    createCampaignError,
    campaignLink,
    ruleSets = [],
    currencies = [],
    loading,
  } = props;

  const defaultCurrency = get(currencies, '[0].exchangeRate.currency');

  const [createCampaignValues, setCreateCampaignValues] = useState<CreateCampaignFields>();

  const countriesList = useCountries(ruleSets.map((r) => r.country));

  const translate = useTranslate();
  const { copied, copy } = useClipboard();

  const activeCurrency = find(currencies, {
    exchangeRate: {
      currency: createCampaignValues?.currency || defaultCurrency,
    },
  });

  const defaultRuleSet = {
    country: '',
    hasPrivatePlacement: false,
    hasPublicOffering: false,
    hasMinimumTicket: false,
    remainingInvitations: 0,
    minimumTicketSize: nullMoney,
  };

  const ruleSet: RuleSet =
    find(ruleSets, {
      country: createCampaignValues?.country,
    }) || defaultRuleSet;

  const { hasPublicOffering, hasMinimumTicket, minimumTicketSize } = ruleSet;

  const ruleTypes = compact([
    hasPublicOffering && CampaignRuleType.PublicOffering,
    hasMinimumTicket && CampaignRuleType.MinimumTicketSize,
  ]) as CampaignRuleType[];

  const ruleTypeElements = {
    [RuleType.MinimumTicketSize]: {
      name: (
        <Translate
          name="ruleType.minimumTicketSize"
          args={[(_, key) => <Currency key={key}>{minimumTicketSize}</Currency>]}
        />
      ),
      validationError: <Translate name="campaignInvitation.errors.minimumTicketSize" />,
      valid: toNumber(investmentTotalMin) >= toNumber(minimumTicketSize),
    },
    [RuleType.PublicOffering]: {
      name: <Translate name="ruleType.publicOffering" />,
      validationError: null,
      valid: true,
    },
  };

  const onSubmitValues = useCallback(
    (values: CreateCampaignFields) => {
      if (values.singleRuleType) {
        values = {
          ...values,
          ruleType: ruleTypes[0] as CampaignRuleType,
        };
        delete values.singleRuleType;
      }

      onSubmit(values);
    },
    [ruleTypes],
  );

  const onChangeValues = useCallback((values: StateValues<CreateCampaignFields>) => {
    setCreateCampaignValues(values);
    onChange(values);
  }, []);

  const debouncedChange = debounce(onChangeValues, 500);

  if (!open) return null;

  if (campaignLink) {
    return (
      <Modal onClose={onCloseAfterSuccess}>
        <ModalHeader>
          <Translate name="campaignInvitation.shareCampaign" />
        </ModalHeader>
        <ModalContent>
          <p>
            <Translate name="campaignInvitation.shareCampaignDescription" />
          </p>
          <Segment padded="tiny" inverted={true}>
            {campaignLink}
          </Segment>
          <Button variant="link" onClick={() => copy(campaignLink)} disabled={copied}>
            {copied ? <Translate name="copy.link.copied" /> : <Translate name="copy.link.copy" />}
          </Button>
        </ModalContent>
        <ModalFooter>
          <ModalFooterButtons
            actionButtons={[
              {
                name: 'overview',
                content: <Translate name="campaignInvitation.campaignOverview" />,
                variant: 'primary',
                size: 'large',
                onClick: onCloseAfterSuccess,
              },
            ]}
          />
        </ModalFooter>
      </Modal>
    );
  }

  return (
    <Modal onClose={onCancelTriggered} className={cx('create-campaign-modal', className)}>
      <CreateCampaignForm
        initial={{
          country: undefined,
          currency: defaultCurrency,
          legalPersonsAllowed: 'true',
          naturalPersonsAllowed: 'true',
          name: null,
        }}
        error={createCampaignError}
        onChange={(values: StateValues<CreateCampaignFields>) => {
          debouncedChange(values);
        }}
        onSubmit={(values: StateValues<CreateCampaignFields>) => {
          return onSubmitValues(values);
        }}
        i18nKey="createCampaignForm"
      >
        <ModalHeader>
          <Translate name="campaignInvitation.create.long" />
        </ModalHeader>
        <ModalContent>
          <CreateCampaignForm.ValueProvider>
            {({ maxNumberOfTokens, minNumberOfTokens, allocation, pricePerToken, ruleType }) => (
              <>
                <CreateCampaignForm.Group name="distributionPlatform" required={true}>
                  <CreateCampaignForm.Select
                    options={distributionPlatforms.map(({ id, platformName }) => ({
                      value: id,
                      label: platformName,
                    }))}
                    portalTarget={document.body}
                    onChange={(distributionPlatform: string) => {
                      // @ts-ignore
                      setCreateCampaignValues({ ...createCampaignValues, distributionPlatform });
                    }}
                  ></CreateCampaignForm.Select>
                </CreateCampaignForm.Group>
                <CreateCampaignForm.Group name="name" required={false}>
                  <CreateCampaignForm.Input />
                </CreateCampaignForm.Group>
                <CreateCampaignForm.Group name="allocation" required={true}>
                  <CreateCampaignForm.Input type="number" min={1} max={allocationUpperLimit} step={1} />
                  {CreateCampaignForm.Validators.Range(
                    1,
                    undefined,
                    <Translate
                      name="createCampaignForm.fields.allocation.requirements.minMax.error"
                      args={[
                        <NumberComponent key={0}>{minNumberOfTokens}</NumberComponent>,
                        <NumberComponent key={1} type="shares">
                          {allocationUpperLimit || 0}
                        </NumberComponent>,
                      ]}
                    />,
                  )}
                  {CreateCampaignForm.Validators.Range(
                    undefined,
                    allocationUpperLimit,
                    <Translate
                      name="createCampaignForm.fields.allocation.requirements.minMax.error"
                      args={[
                        <NumberComponent key={0}>{minNumberOfTokens}</NumberComponent>,
                        <NumberComponent key={1} type="shares">
                          {allocationUpperLimit || 0}
                        </NumberComponent>,
                      ]}
                    />,
                    allocationUpperLimit && (
                      <Translate
                        name="createCampaignForm.fields.allocation.requirements.minMax.helper"
                        args={[
                          <NumberComponent key={1} type="shares">
                            {allocationUpperLimit}
                          </NumberComponent>,
                        ]}
                      />
                    ),
                  )}
                  {CreateCampaignForm.Validators.Integer()}
                </CreateCampaignForm.Group>
                <CreateCampaignForm.Group
                  required={true}
                  name="minNumberOfTokens"
                  info={
                    minNumberOfTokens && (
                      <Translate
                        name="createCampaignForm.fields.minNumberOfTokens.information"
                        args={[(_, key) => <NumberComponent key={key}>{toNumber(minNumberOfTokens)}</NumberComponent>]}
                      />
                    )
                  }
                >
                  <CreateCampaignForm.Input type="number" />
                  {CreateCampaignForm.Validators.Range(
                    1,
                    toNumber(allocation) || undefined,
                    toNumber(allocation) && (
                      <Translate
                        name="createCampaignForm.fields.minNumberOfTokens.requirements.minMax.error"
                        args={[
                          <NumberComponent key={0}>{minNumberOfTokens}</NumberComponent>,
                          <NumberComponent key={1} type="shares">
                            {allocation}
                          </NumberComponent>,
                        ]}
                      />
                    ),
                    toNumber(allocation) && (
                      <Translate
                        name="createCampaignForm.fields.minNumberOfTokens.requirements.minMax.helper"
                        args={[
                          <NumberComponent key={0} type="shares">
                            {allocation}
                          </NumberComponent>,
                        ]}
                      />
                    ),
                  )}
                  {CreateCampaignForm.Validators.Integer()}
                </CreateCampaignForm.Group>
                <CreateCampaignForm.Group
                  name="maxNumberOfTokens"
                  required={true}
                  info={
                    // TODO(mara-cashlink): only show info if maxNumberOfTokens valid?
                    maxNumberOfTokens && (
                      <Translate
                        name="createCampaignForm.fields.maxNumberOfTokens.information"
                        args={[(_, key) => <NumberComponent key={key}>{toNumber(maxNumberOfTokens)}</NumberComponent>]}
                      />
                    )
                  }
                >
                  <CreateCampaignForm.Input type="number" />
                  {!!toNumber(allocation) &&
                    !!toNumber(minNumberOfTokens) &&
                    CreateCampaignForm.Validators.Range(
                      toNumber(minNumberOfTokens) || 1,
                      undefined,
                      undefined,
                      toNumber(minNumberOfTokens) && (
                        <Translate
                          name="createCampaignForm.fields.maxNumberOfTokens.requirements.min.helper"
                          args={[
                            <NumberComponent key={0} type="shares">
                              {toNumber(minNumberOfTokens) || 1}
                            </NumberComponent>,
                          ]}
                        />
                      ),
                    )}
                  {!!toNumber(allocation) &&
                    !!toNumber(minNumberOfTokens) &&
                    CreateCampaignForm.Validators.Range(
                      undefined,
                      toNumber(allocation) || undefined,
                      undefined,
                      toNumber(allocation) && (
                        <Translate
                          name="createCampaignForm.fields.maxNumberOfTokens.requirements.max.helper"
                          args={[
                            <NumberComponent key={0} type="shares">
                              {allocation}
                            </NumberComponent>,
                          ]}
                        />
                      ),
                    )}
                  {CreateCampaignForm.Validators.Integer()}
                </CreateCampaignForm.Group>
                {currencies.length > 1 && (
                  <CreateCampaignForm.Group
                    name="currency"
                    required={true}
                    info={
                      activeCurrency &&
                      activeCurrency.exchangeRate.currency !== activeCurrency.baseCurrency && (
                        <Translate
                          name="createCampaignForm.fields.currency.information"
                          args={[
                            (_, key) => (
                              <Currency key={key}>
                                {{
                                  currency: activeCurrency.baseCurrency as any,
                                  amount: '100',
                                  decimals: 2,
                                }}
                              </Currency>
                            ),
                            (_, key) => (
                              <Currency key={key} decimals={6}>
                                {activeCurrency.exchangeRate}
                              </Currency>
                            ),
                          ]}
                        />
                      )
                    }
                  >
                    <CreateCampaignForm.Select
                      options={currencies.map(({ exchangeRate }) => ({
                        value: exchangeRate.currency,
                        label: translate(`currencies.${exchangeRate.currency}`),
                      }))}
                    />
                  </CreateCampaignForm.Group>
                )}
                <CreateCampaignForm.Group
                  name="pricePerToken"
                  required={true}
                  info={
                    !calculationError &&
                    !loading &&
                    pricePerToken &&
                    investmentTotalMin &&
                    investmentTotalMax && (
                      <Translate
                        name="createCampaignForm.fields.pricePerToken.information"
                        args={[
                          (_, key) => <Currency key={key}>{investmentTotalMin}</Currency>,
                          (_, key) => <Currency key={key}>{investmentTotalMax}</Currency>,
                        ]}
                      />
                    )
                  }
                >
                  <CreateCampaignForm.Input type="number" isCurrency />
                  {CreateCampaignForm.Validators.Range(
                    toNumber(backToBackMinPrice) || 0,
                    undefined,
                    <Translate
                      name="createCampaignForm.fields.pricePerToken.requirements.min.error"
                      args={[(_, key) => <Currency key={key}>{backToBackMinPrice}</Currency>]}
                    />,
                    <Translate
                      name="createCampaignForm.fields.pricePerToken.requirements.min.helper"
                      args={[(_, key) => <Currency key={key}>{backToBackMinPrice}</Currency>]}
                    />,
                  )}
                  {CreateCampaignForm.Validators.MaxDecimals(13)}
                </CreateCampaignForm.Group>
                <CreateCampaignForm.Group name="country" required={true}>
                  <CreateCampaignForm.Select
                    options={countriesList}
                    portalTarget={document.body}
                    onChange={(country: CountryEnum) => {
                      // @ts-ignore
                      setCreateCampaignValues({ ...createCampaignValues, country });
                    }}
                  ></CreateCampaignForm.Select>
                </CreateCampaignForm.Group>
                <CreateCampaignForm.Group name="legalPersonsAllowed" required={true}>
                  <CreateCampaignForm.GroupToggle>
                    {['true', 'false'].map((option) => (
                      <CreateCampaignForm.Radio value={option}>
                        <Translate name={`commonOptions.${option}`} />
                      </CreateCampaignForm.Radio>
                    ))}
                  </CreateCampaignForm.GroupToggle>
                  <CreateCampaignForm.Validation
                    validate={(legalPersonsAllowed, { naturalPersonsAllowed }) =>
                      naturalPersonsAllowed !== 'false' || legalPersonsAllowed !== 'false'
                    }
                    error={<Translate name="campaignInvitation.errors.naturalLegalPersonsAllowed" />}
                  />
                </CreateCampaignForm.Group>
                <CreateCampaignForm.Group name="naturalPersonsAllowed" required={true}>
                  <CreateCampaignForm.GroupToggle>
                    {['true', 'false'].map((option) => (
                      <CreateCampaignForm.Radio value={option}>
                        <Translate name={`commonOptions.${option}`} />
                      </CreateCampaignForm.Radio>
                    ))}
                  </CreateCampaignForm.GroupToggle>
                  <CreateCampaignForm.Validation
                    validate={(naturalPersonsAllowed, { legalPersonsAllowed }) =>
                      naturalPersonsAllowed !== 'false' || legalPersonsAllowed !== 'false'
                    }
                    error={<Translate name="campaignInvitation.errors.naturalLegalPersonsAllowed" />}
                  />
                </CreateCampaignForm.Group>
                {ruleTypes.length === 1 && (
                  <CreateCampaignForm.Group name="singleRuleType" required={true}>
                    <CreateCampaignForm.Checkbox>{ruleTypeElements[ruleTypes[0]].name}</CreateCampaignForm.Checkbox>
                  </CreateCampaignForm.Group>
                )}
                {ruleTypes.length > 1 && (
                  <CreateCampaignForm.Group name="ruleType" required={true}>
                    <CreateCampaignForm.GroupToggle>
                      {ruleTypes.map((ruleTypeOption) => (
                        <CreateCampaignForm.Radio value={ruleTypeOption}>
                          {ruleTypeElements[ruleTypeOption].name}
                        </CreateCampaignForm.Radio>
                      ))}
                    </CreateCampaignForm.GroupToggle>
                  </CreateCampaignForm.Group>
                )}
                {ruleType && minNumberOfTokens && pricePerToken && !ruleTypeElements[ruleType].valid && (
                  <Hint variant="danger">{ruleTypeElements[ruleType].validationError}</Hint>
                )}
              </>
            )}
          </CreateCampaignForm.ValueProvider>
          <CreateCampaignForm.GenericErrorMessages />
        </ModalContent>
        <ModalFooter>
          <ModalFooterButtons
            actionButtons={[
              {
                name: 'cancel',
                content: <Translate name="common.cancel" />,
                size: 'large',
                onClick: onCloseAfterSuccess,
              },
              {
                name: 'submit',
                type: 'submit',
                variant: 'primary',
                loading: loading,
                content: <Translate name="campaignInvitation.create.short" />,
                size: 'large',
              },
            ]}
          />
        </ModalFooter>
      </CreateCampaignForm>
    </Modal>
  );
};

export default CreateCampaignModal;
