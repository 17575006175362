import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import Button from 'ui/atoms/button';
import useApiCall from 'hooks/use-api-call';
import { AdminProductDetail, InvestmentCalculation, InvestmentInvitation, IssuerInvestmentRules } from 'api/models';
import { AdminApi } from 'api/apis';
import { toMoney, toNumber } from 'ui/helper/money';
import { handleError } from 'ui/helper/error-handling';
import useClearAfterTimeout from 'ui/hooks/use-clear-after-timeout';
import useTranslate from 'ui/hooks/use-translate';
import CreateInvestmentInvitationModal, {
  CreateInvestmentInvitationFields,
} from './create-investment-invitation-modal';
import Tooltip from 'src/ui/atoms/tooltip';
import Translate from 'src/ui/atoms/translate';
import { currentIssuerHasManageListingsPermission } from 'src/apps/issuer/helpers/issuerPermissions';

interface IssuerCreateInvestmentInvitationProps {
  hasListings: boolean;
  onCloseAfterSuccess?: () => void;
  product: AdminProductDetail;
}

const IssuerCreateInvestmentInvitation: FunctionComponent<IssuerCreateInvestmentInvitationProps> = (props) => {
  const { onCloseAfterSuccess = () => {}, children, product, hasListings } = props;

  const [isCreateInvitationOpen, setIsCreateInvitationOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { withApi, makeAuthenticatedApi, error } = useApiCall(true);

  const {
    withApi: withCalcApi,
    loading: loadingCalcApi,
    makeAuthenticatedApi: makeAuthenticatedCalcApi,
    error: calculationError,
  } = useApiCall(false);

  const {
    withApi: withTokensInvitationApi,
    loading: loadingTokensInvitationApi,
    makeAuthenticatedApi: makeAuthenticatedTokensInvitationApi,
    error: createTokensInvitationApiError,
  } = useApiCall(false);

  const [values, setValues] = useState<CreateInvestmentInvitationFields>();

  const [investmentInvitation, setInvestmentInvitation] = useState<InvestmentInvitation>();

  const [calculations, setCalculations] = useState<InvestmentCalculation>();
  const [ruleSets, setRuleSets] = useState<IssuerInvestmentRules[]>();

  const hasManageListingsPermission = useMemo(
    () => currentIssuerHasManageListingsPermission(product.issuer.currentUserPermissions),
    [product.issuer.currentUserPermissions],
  );

  const tokensCalcApi: AdminApi = useMemo(() => makeAuthenticatedCalcApi(AdminApi), [makeAuthenticatedCalcApi]);

  const invitationRulesApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const tokensInvitationApi: AdminApi = useMemo(
    () => makeAuthenticatedTokensInvitationApi(AdminApi),
    [makeAuthenticatedTokensInvitationApi],
  );

  const { getRemainingError } = handleError({
    error: useClearAfterTimeout(error),
    translate: useTranslate(),
  });

  const answerErrors = getRemainingError();

  const openInvestmentInvitationCreation = async () => {
    setInvestmentInvitation(undefined);
    setValues(undefined);
    setIsCreateInvitationOpen(true);
    loadData();
  };

  const createInvestmentInvitation = useCallback(
    (values) => {
      if (!product.id) return;
      withTokensInvitationApi(async () => {
        const investmentInvitation = await tokensInvitationApi.adminTokensInvitationCreate({
          id: product.id,
          investmentInvitationCreationRequest: {
            distributionPlatformId: values.distributionPlatform,
            email: values.email,
            name: values.fullName,
            maxNumberOfTokens: values.maxNumberOfTokens,
            pricePerToken: toMoney(values.pricePerToken),
            ruleType: values.ruleType,
            country: values.country,
            currency: values.currency,
          },
        });
        setInvestmentInvitation(investmentInvitation);
      });
    },
    [withTokensInvitationApi, product.id],
  );

  const loadData = useCallback(() => {
    withApi(async () => {
      if (!product?.id) return null;

      const rules = await invitationRulesApi.adminTokensInvitationRulesList({
        id: product.id,
      });

      setRuleSets(rules);
    });
  }, [withApi, product.id, invitationRulesApi, setRuleSets]);

  const getCalculations = useCallback(() => {
    withCalcApi(async (utilities) => {
      const { takeLatest } = utilities;

      const calculations = await invitationRulesApi.adminTokensCalculationsRetrieve({
        id: product.id,
        requestedTokens: values?.maxNumberOfTokens ? toNumber(values?.maxNumberOfTokens) : undefined,
        pricePerToken: values?.pricePerToken ? toNumber(values?.pricePerToken) : undefined,
      });

      takeLatest(() => {
        setCalculations(calculations);
      });
    });
  }, [withCalcApi, tokensCalcApi, product.id, values?.maxNumberOfTokens, values?.pricePerToken]);

  useEffect(() => {
    if (!product?.id) return;
    getCalculations();
  }, [product.id, values?.maxNumberOfTokens, values?.pricePerToken]);

  useEffect(() => {
    if (answerErrors) setIsLoading(false);
  }, [answerErrors]);

  useEffect(() => {
    setIsLoading(isCreateInvitationOpen && (!product?.id || !calculations));
  }, [product.id, calculations, isCreateInvitationOpen]);

  if (!product?.id) return null;

  return (
    <>
      <>
        {isCreateInvitationOpen && product.id && (
          <CreateInvestmentInvitationModal
            loading={loadingCalcApi || loadingTokensInvitationApi}
            onCloseAfterSuccess={() => {
              onCloseAfterSuccess();
              setIsCreateInvitationOpen(false);
            }}
            open={true}
            onSubmit={createInvestmentInvitation}
            onCancelTriggered={() => setIsCreateInvitationOpen(false)}
            onChange={setValues}
            currencies={product.currencies}
            maxNumberOfTokensUpperLimit={calculations && calculations.requestedTokens + calculations.remainingTokens}
            distributionPlatforms={product.distributionPlatforms}
            showShareInfo={product.showShareInfo}
            backToBackMinPrice={product.backToBackMinPrice}
            valuation={(calculations && calculations.preMoneyValuation) || undefined}
            investmentTotal={calculations && calculations.investmentTotal}
            ruleSets={ruleSets}
            calculationError={!!calculationError}
            createInvitationError={createTokensInvitationApiError}
            investmentInvitationLink={investmentInvitation?.invitationLink}
          />
        )}

        {!product ||
          !product.id ||
          (product.remainingAmount > 0 && (
            <Button
              variant="primary"
              loading={isLoading}
              disabled={!product || !product.id || (hasManageListingsPermission && !hasListings)}
              onClick={openInvestmentInvitationCreation}
              error={answerErrors}
            >
              {children}
            </Button>
          ))}
        {product && product.remainingAmount <= 0 && (
          <Tooltip maxWidth={188} content={<Translate name="campaignInvitation.noUnitsAllocation" />}>
            <Button variant="primary" disabled={true}>
              {children}
            </Button>
          </Tooltip>
        )}
      </>
    </>
  );
};

export default IssuerCreateInvestmentInvitation;
